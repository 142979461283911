<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>CASES YOU MIGHT NEED TO USE VPN:</p>
								<p><br /></p>
								<p>-If you are from the UK (England, Scotland, Wales and Northern Ireland) or UAE a VPN is mostly needed.</p>
								<p>
									We've noticed <strong>UK </strong><strong>ISP</strong> (Internet service provider) are
									<strong>blocking our servers during Main Event Games</strong>, and causing our whole entire service not to work during that time
									<strong>ONLY </strong>for users that are located in <strong>UK region</strong>.
								</p>
								<p><br /></p>
								<p>We advise you to bypass this issue by using VPN.</p>
								<p>You'll see black screen or just empty error while its happening.</p>
								<p><br /></p>
								<p>-If your service is working, and your ISP isn't blocking our service, then you don't need any VPN.</p>
								<p>Our service is highly encrypted, no one can know besides you what you're watching or doing.</p>
								<p>We use RSA 4096 with sha256 Encryption, which is <strong>higher than</strong><strong> what your bank uses</strong>.</p>
								<p><br /></p>
								<p><strong>So you can be safe knowing you are safe with us.</strong></p>
								<p><br /></p>
								<p>-Some ISPs are throttling quite heavily or having congestion on their lines to specific datacenter/networks.</p>
								<p>To avoid that, we suggest using VPN, since it uses different routes to avoid that problem.</p>
								<p><br /></p>
								<p>In case you don't see improvement, switch locations (West Europe countries/East US).</p>
								<p>
									Run <a :href="brandInfo.speedTestLink">{{ brandInfo.speedTestLink }}</a> test each time you choose a location in the VPN.
								</p>
								<p>
									Keep switching location till you get the best results in our
									<a :href="brandInfo.speedTestLink" rel="noreferrer">{{ brandInfo.speedTestLink }}</a> test.
								</p>
								<p>Using VPN will ensure smoother experience !</p>
								<p><br />Recommended VPN providers are:<br /><br /><a href="https://nordvpn.com/" rel="noreferrer noopener">NordVPN</a></p>
								<p><a href="https://www.ipvanish.com">IPVanish</a></p>
								<!-- <p><a href="https://surfshark.com/" rel="noreferrer noopener">ExpressVPN</a></p> -->
								<p><a href="https://surfshark.com/">Surfshark </a></p>
								<p><br /></p>
								<p><br /></p>
								<p>Suggested VPN server locations are</p>
								<p>-USA (East)</p>
								<p>-Canada</p>
								<p>-Denmark</p>
								<p>-Sweden</p>
								<p>-Switzerland</p>
								<p>-Norway</p>
								<p>-Latvia</p>
								<p>-Finland</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'doINeedAVpn',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	computed: {
		...mapState({
			brandInfo: (state) => state.brandInfo
		})
	}
};
</script>

<style scoped></style>
